define("pilots/helpers/contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function contains(_ref) {
    let [item, array] = _ref;
    return array.includes(item);
  });
  _exports.default = _default;
});