define("pilots/components/client-mission", ["exports", "jquery", "pilots/config/environment", "pilots/mixins/google-init-mixin"], function (_exports, _jquery, _environment, _googleInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  var ClientMissionComponent,
    indexOf = [].indexOf || function (item) {
      for (var i = 0, l = this.length; i < l; i++) {
        if (i in this && this[i] === item) return i;
      }
      return -1;
    };
  ClientMissionComponent = Ember.Component.extend(_googleInitMixin.default, {
    tagName: '',
    sessionAccount: Ember.inject.service(),
    hideScheduling: true,
    isShowingModal: false,
    isAuthDetailsExpanded: false,
    orderOfSteps: ['Preflight', 'Shotlist', 'Flyapp', 'Onsite'],
    milesCountries: ['United States', 'United States of America', 'US', 'USA', 'GB'],
    blogUrl: _environment.default.BLOG_URL,
    disableRescheduleButton: Ember.computed('disableReschedule', 'requireContactToReschedule', function () {
      return this.get('disableReschedule') || this.get('requireContactToReschedule');
    }),
    readyToSubmit: Ember.computed('model.mission.status', 'assetsClassified', function () {
      return this.get('model.mission.status') === 'assets_uploaded' && this.get('assetsClassified');
    }),
    disableReschedule: Ember.computed('model.mission.missionComplete', 'model.mission.admin_scheduled', 'model.mission.is_on_hold', function () {
      return this.get('model.mission.admin_scheduled') || this.get('model.mission.is_on_hold') || this.get('model.mission.missionComplete');
    }),
    requireContactToReschedule: Ember.computed('model.mission.scheduled_at_start', 'model.mission.scheduled_at_end', 'model.mission.require_contact_to_reschedule', function () {
      return this.get('model.mission.scheduled_at_start') && this.get('model.mission.scheduled_at_end') && this.get('model.mission.require_contact_to_reschedule');
    }),
    airspaceRequireAuth: Ember.computed('model.mission.airspace_briefing.requires_auth', function () {
      return this.get('model.mission.airspace_briefing.requires_auth');
    }),
    airspaceNotices: Ember.computed('model.mission.airspace_briefing.notices', function () {
      return this.get('model.mission.airspace_briefing.notices') || [];
    }),
    airspaceCertificates: Ember.computed('model.mission.airspace_certificates', function () {
      return this.get('model.mission.airspace_certificates') || [];
    }),
    afterGoogleLoaded: function () {
      var distance, distanceKM, distanceMiles, latLngA, latLngB, ref;
      latLngA = new google.maps.LatLng(this.get('model.pilot.latitude'), this.get('model.pilot.longitude'));
      latLngB = new google.maps.LatLng(Number(this.get('model.mission.location.latitude')), Number(this.get('model.mission.location.longitude')));
      distance = google.maps.geometry.spherical.computeDistanceBetween(latLngA, latLngB);
      distanceMiles = (distance / 1609).toString();
      if (ref = this.get('model.pilot.country'), indexOf.call(this.milesCountries, ref) >= 0) {
        if (distanceMiles.indexOf('.') && distanceMiles.indexOf('.') + 1 < distanceMiles.length) {
          return this.set('missionDistance', distanceMiles.substring(0, distanceMiles.indexOf('.') + 2) + ' mi');
        } else {
          return this.set('missionDistance', distanceMiles + ' mi');
        }
      } else {
        distanceKM = (distance / 1000).toString();
        if (distanceKM.indexOf('.') && distanceKM.indexOf('.') + 1 < distanceKM.length) {
          return this.set('missionDistance', distanceKM.substring(0, distanceKM.indexOf('.') + 2) + ' km');
        } else {
          return this.set('missionDistance', distanceKM + ' km');
        }
      }
    },
    acceptedDrones: Ember.computed('model.mission.package.drones', 'model.pilotDrones', function () {
      var hidden, pilotDrones, visible;
      visible = [];
      hidden = [];
      pilotDrones = this.get('model.pilotDrones').map(function (drone) {
        return drone.get('drone.name');
      });
      this.get('model.mission.package.drones').forEach(function (drone) {
        if (pilotDrones.includes(drone.get('name'))) {
          return visible.push(drone.get('name'));
        } else {
          return hidden.push(drone.get('name'));
        }
      });
      return {
        visible: visible,
        hidden: hidden
      };
    }),
    acceptedCameras: Ember.computed('model.mission.package.droneCameras', 'model.pilotDrones', function () {
      var hidden, pilotCameras, visible;
      visible = [];
      hidden = [];
      pilotCameras = [];
      this.get('model.pilotDrones').forEach(function (drone) {
        return drone.get('cameras').forEach(function (camera) {
          return pilotCameras.push(camera.get('name'));
        });
      });
      this.get('model.mission.package.droneCameras').forEach(function (camera) {
        if (pilotCameras.includes(camera.get('name'))) {
          return visible.push(camera.get('name'));
        } else {
          return hidden.push(camera.get('name'));
        }
      });
      return {
        visible: visible,
        hidden: hidden
      };
    }),
    acceptedDevices: Ember.computed('model.mission.package.devices', 'model.pilotDevices', function () {
      var hidden, pilotDevices, visible;
      pilotDevices = this.get('model.pilotDevices').map(function (device) {
        return device.get('device.name');
      });
      visible = [];
      hidden = [];
      this.get('model.mission.package.devices').forEach(function (device) {
        if (pilotDevices.includes(device.get('name'))) {
          return visible.push(device.get('name'));
        } else {
          return hidden.push(device.get('name'));
        }
      });
      return {
        visible: visible,
        hidden: hidden
      };
    }),
    requiredEquipment: Ember.computed('model.mission.package.pilotEquipments', function () {
      var visible;
      visible = this.get('model.mission.package.pilotEquipments').map(function (equipment) {
        return equipment.get('name');
      });
      return {
        visible: visible
      };
    }),
    showContactInfoBox: Ember.computed('model.mission', function () {
      return !!this.get('model.mission.onsite_contact.call_action') && this.get('model.mission.onsite_contact.call_action') !== 'Not needed';
    }),
    hasOnsiteInfo: Ember.computed('model.mission.pilot_script', 'showContactInfoBox', function () {
      return this.get('model.mission.pilot_script') || this.get('showContactInfoBox');
    }),
    showUploadOther: Ember.computed('model.mission.flight_app', 'model.mission.flight_app.delivery_to_url', function () {
      return this.get('model.mission.flight_app') && (this.get('model.mission.flight_app.delivery_to_url') || this.get('model.mission.isCyberduck'));
    }),
    updateStatus: function (newStatus) {
      var _data, _mission, _this, headers;
      _this = this;
      _mission = this.get('model.mission');
      headers = this.get('sessionAccount.headers');
      _data = {
        pilot_comment: this.get('model.mission.pilot_comment')
      };
      return _jquery.default.ajax({
        url: _environment.default.api.host + "/v1/pilots/missions/" + (_mission.id + "/status/" + newStatus),
        type: 'PATCH',
        dataType: 'json',
        headers: headers,
        data: _data
      }).then(function (response) {
        return _mission.reload();
      }, function (response) {
        return console.log('fail');
      });
    },
    actions: {
      goToUpload: function () {
        return this.goToUpload();
      },
      expandSection: function (section) {
        var i, len, ref, results, step;
        ref = this.orderOfSteps;
        results = [];
        for (i = 0, len = ref.length; i < len; i++) {
          step = ref[i];
          if (step !== section) {
            results.push(this.set('expanded' + step, false));
          } else {
            results.push(void 0);
          }
        }
        return results;
      },
      toggleShowLaancAuthAcquiredModal: function () {
        return this.set('model.isShowingLaancAuthAcquiredModal', false);
      },
      toggleReSchedulingSuccess: function () {
        this.set('isShowingRescheduleSuccessModal', !this.get('isShowingRescheduleSuccessModal'));
        if (this.get('isShowingRescheduleSuccessModal') === false) {
          this.set('showRescheduleReason', false);
          return this.set('showRescheduleNotes', false);
        }
      },
      toggleRescheduleReason: function () {
        return this.set('showRescheduleReason', !this.get('showRescheduleReason'));
      },
      update: function (mission) {
        this.set('hideScheduling', true);
        return this.updateAction(mission);
      },
      reschedule: function () {
        return this.get('model.reschedule').save().then(function (_this) {
          return function (response) {
            _this.set('model.mission.scheduled_at_start', _this.get('model.reschedule.scheduled_at_start'));
            _this.set('model.mission.scheduled_at_end', _this.get('model.reschedule.scheduled_at_end'));
            _this.get('model.mission').reload();
            return _this.send('toggleReSchedulingSuccess');
          };
        }(this));
      },
      openMaps: function () {
        var url, win;
        if (this.get('model.source')) {
          return this.openMaps();
        } else {
          if (this.get('model.mission.location.formatted_address')) {
            url = 'http://maps.google.com/?q=' + this.get('model.mission.location.formatted_address');
          } else {
            url = 'http://maps.google.com/?q=' + this.get('model.mission.location.latitude') + ',' + this.get('model.mission.location.longitude');
          }
          win = window.open(url, '_blank');
          if (win) {
            return win.focus();
          }
        }
      }
    }
  });
  var _default = ClientMissionComponent;
  _exports.default = _default;
});